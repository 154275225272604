<template>
  <b-container fluid>
    <h3 class="mb-5 text-secondary">
      <span v-if="isNewCommunication">
        {{ $t("page.communication.createCommunication") }}
      </span>
      <span v-else>
        {{ $t("page.communication.updateCommunication") }}
      </span>
    </h3>
    <v-stepper
      ref="stepper"
      :withDivider="false"
      :steps="steps"
      v-model="step"
      class="pb-4 mx-4"
    >
      <template slot="step-1">
        {{ $t("page.communication.stepOne.title") }}
      </template>
      <template slot="step-2">
        {{ $t("page.communication.stepTwo.title") }}
      </template>
      <template slot="step-3">
        {{ $t("page.communication.stepThree.title") }}
      </template>
    </v-stepper>

    <b-card :header-html="header">
      <template v-if="step === 1">
        <!-- Step 1 Content -->
        <users-step
          :isNewCommunication="isNewCommunication"
          @isSelected="checkSelections"
        />
      </template>
      <template v-if="step === 2">
        <!-- Step 2 Content -->
        <step-three @formRready="checkForm"></step-three>
      </template>
      <template v-if="step === 3">
        <!-- Step 3 Content -->
        <step-four></step-four>
      </template>
      <b-row align-h="between">
        <b-col cols="6">
          <b-button
            class="text-white"
            v-if="step !== 1 && step !== 3"
            @click="previous"
          >
            {{ $t("button.previous") }}
          </b-button>
        </b-col>
        <b-col cols="6 text-right">
          <b-button
            v-if="step === 1"
            :disabled="!canNext"
            class="text-white"
            @click="next"
          >
            {{ $t("button.next") }}
          </b-button>
          <b-button
            v-else-if="step === 2"
            class="text-white"
            :disabled="!canSave"
            @click="submitCommunication"
          >
            <div v-if="isNewCommunication">
              {{ $t("button.save") }}
            </div>
            <div v-else>
              {{ $t("button.update") }}
            </div>
          </b-button>
          <b-button v-else>
            <div class="text-white" @click="updateLastStep">
              {{ $t("button.save") }}
            </div>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
import UsersStep from "../components/Communication/Groups/UsersStep";
import StepThree from "../components/Communication/CreationSteps/StepThree";
import StepFour from "../components/Communication/CreationSteps/StepFour";

import { VStepper } from "vue-stepper-component";

export default {
  components: {
    UsersStep,
    StepThree,
    StepFour,
    VStepper
  },
  data() {
    return {
      steps: 3,
      step: 1,
      canSave: false,
      canNext: false
    };
  },
  computed: {
    header() {
      var renderData = "";
      var icon = '<b-icon icon="file-earmark"></b-icon>';
      if (this.step === 1) {
        renderData =
          "<h5>" + this.$t("page.communication.stepOne.header") + "</h5>";
      } else if (this.step === 2) {
        renderData =
          "<h5>" + this.$t("page.communication.stepTwo.header") + "</h5>";
      } else {
        renderData =
          "<h5>" + this.$t("page.communication.stepThree.header") + "</h5>";
      }
      return icon + renderData;
    },
    communication() {
      return this.$store.getters["communication/getCommunication"];
    },
    settings() {
      return this.$store.getters["communication/getSettings"];
    },
    recipients() {
      return this.$store.getters["communication/getRecipients"];
    },
    communicationInfo() {
      return this.$store.getters["communication/getCommunicationForm"];
    },
    isNewCommunication() {
      return this.$router.history.current.name === "NewGroupCommunication";
    }
  },
  methods: {
    getCommunicationInfo() {
      const payload = {
        type: this.communicationInfo.type,
        subject: this.communicationInfo.subject,
        body: this.communicationInfo.body
      };

      if (this.isNewCommunication) {
        const documents = this.communicationInfo.documents;
        const recipients = this.recipients;

        if (documents && documents.length > 0)
          payload["documents[]"] = documents;

        if (recipients && recipients.length > 0) {
          for (let i = 0; i < recipients.length; i++) {
            payload[`recipients[${i}]`] = recipients[i];
          }
        }
      }

      return payload;
    },
    getDataForUpdate() {
      const { communicationId } = this.$route.params;
      const communicationInfo = this.getCommunicationInfo();
      const {
        addedRecipients,
        removedRecipients
      } = this.getRecipientsOnUpdate();
      const documments = this.$store.getters["communication/getEditDocuments"];
      const addedDocuments = documments.add;
      const removedDocuments = documments.remove;

      return {
        communicationId,
        communicationInfo,
        addedRecipients,
        removedRecipients,
        addedDocuments,
        removedDocuments
      };
    },
    getRecipientsOnUpdate() {
      const recipients = this.$store.getters["communication/getEditRecipients"];
      const filteredGroupUsers = this.$store.getters[
        "communication/getUsers"
      ].map(cgu => cgu._id);

      const addedRecipients = [];
      const addedRecipientsCopy = [...recipients.add];
      const removedRecipients = [...recipients.remove];
      const remoteStoredUsers = recipients.selected;
      for (let index = 0; index < remoteStoredUsers.length; ++index) {
        if (
          !filteredGroupUsers.includes(remoteStoredUsers[index]) &&
          !removedRecipients.includes(remoteStoredUsers[index])
        ) {
          removedRecipients.push(remoteStoredUsers[index]);
        }
      }

      for (let index = 0; index < addedRecipientsCopy.length; ++index) {
        if (filteredGroupUsers.includes(addedRecipientsCopy[index])) {
          addedRecipients.push(addedRecipientsCopy[index]);
        }
      }

      return { addedRecipients, removedRecipients };
    },
    submitCommunication() {
      if (this.isNewCommunication) this.createCommunication();
      else this.updateCommunication();
    },
    async createCommunication() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        const payload = this.getCommunicationInfo();

        const response = await this.$store.dispatch(
          "communication/createCommunicationWithoutCondominium",
          { payload }
        );

        await this.$store.dispatch("communication/retrieveCommunicationPure", {
          communicationId: response?.body?._id
        });

        this.next();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async updateCommunication() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        const {
          communicationId,
          communicationInfo,
          addedRecipients,
          removedRecipients,
          addedDocuments,
          removedDocuments
        } = this.getDataForUpdate();

        await this.$store.dispatch("communication/updateCommunicationPure", {
          communicationId,
          payload: communicationInfo
        });

        if (addedRecipients && addedRecipients.length > 0) {
          await this.$store.dispatch(
            "communication/addCommunicationRecipientsPure",
            {
              communicationId,
              payload: {
                recipients: addedRecipients
              }
            }
          );
        }

        if (removedRecipients && removedRecipients.length > 0) {
          for (let i = 0; i < removedRecipients.length; i++) {
            await this.$store.dispatch(
              "communication/deleteCommunicationRecipientPure",
              {
                communicationId,
                recipientId: removedRecipients[i]
              }
            );
          }
        }

        if (addedDocuments && addedDocuments.length > 0) {
          await this.$store.dispatch("communication/addDocumentsPure", {
            communicationId,
            payload: { "documents[]": addedDocuments }
          });
        }

        if (removedDocuments && removedDocuments.length > 0) {
          for (let i = 0; i < removedDocuments.length; i++) {
            await this.$store.dispatch("communication/deleteDocumentPure", {
              communicationId,
              documentId: removedDocuments[i]
            });
          }
        }

        await this.$store.dispatch("communication/retrieveCommunicationPure", {
          communicationId
        });

        this.next();
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    async updateLastStep() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        const payload = {
          type: this.communication.type,
          subject: this.communication.subject,
          body: this.communication.body,
          readReceipt: this.settings.readReceipt,
          emailToAppUsers: this.settings.emailToAppUsers,
          paperToMailUsers: this.settings.paperToMailUsers
        };

        await this.$store.dispatch("communication/updateCommunicationPure", {
          communicationId: this.communication._id,
          payload
        });

        this.$router.replace({ name: "Communications" });
        this.$store.commit("communication/RESET");
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    checkForm(check) {
      this.canSave = check;
    },
    checkSelections(check) {
      this.canNext = check;
    },
    next() {
      this.$refs.stepper.next();
    },
    previous() {
      this.$refs.stepper.previous();
    }
  }
};
</script>

<style lang="scss" scoped>
.v-stepper /deep/ .v-stepper-root /deep/ .v-step {
  pointer-events: none;
}
.v-stepper /deep/ {
  .v-stepper-root {
    justify-content: space-around;
  }
  .v-step {
    flex: unset;
    // /deep/ .label {
    //   display: unset;
    // }
    /deep/ .index {
      color: var(--primary);
      background-color: var(--gray-light) !important;
      border: unset;
      justify-items: center;
    }
    /deep/ .title {
      color: black;
      font-weight: bold;
    }
    .is-active {
      /deep/ .title {
        font-weight: 400;
      }
    }
  }
}
</style>
