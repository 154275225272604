<template>
  <b-container fluid>
    <!-- User Interface controls -->
    <b-row class="justify-content-between">
      <b-col lg="6">
        <search-input
          v-model="filterSearch"
          label="search"
          :placeholder="$t('page.consumerUser.searchPlaceholder')"
        />
      </b-col>
      <b-col v-if="$is1Board" lg="6" class="text-lg-right mb-4">
        <user-filters-show
          :status="groupUserFilters.status"
          :groups="groupUserFilters.groups"
          :allGroups="allGroups"
          :groupsLoading="groupsLoading"
          :groupsError="groupsError"
          @update="applyFilters"
        />
      </b-col>
      <b-col v-if="$isDomi" lg="6">
        <b-form-group :label="$t('role')" label-class="font-weight-bold">
          <b-form-checkbox-group v-model="filterRole" id="recipients-role">
            <b-form-checkbox
              v-for="(role, index) in consumerRoleOptions"
              :key="index"
              :value="role.value"
            >
              {{ $t(role.label) }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
      <b-col v-if="isNewCommunication" lg="12" class="text-right pb-3">
        <b-button class="mr-3 text-white" size="sm" @click="selectAllRows">
          {{ $t("button.selectAll") }}
        </b-button>
        <b-button class="text-white" size="sm" @click="clearSelected">
          {{ $t("button.clearAll") }}
        </b-button>
      </b-col>
    </b-row>

    <b-table
      ref="table"
      :fields="fields"
      :items="filteredRecipients"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter="filterRole"
      :filter-included-fields="filterOn"
      :filter-function="customFilter"
      responsive="sm"
      select-mode="multi"
      outlined
      hover
      show-empty
      small
      @row-clicked="onRowClicked"
      @filtered="onFiltered"
    >
      <template #empty>
        <h6 class="text-center text-italic text-uppercase">
          {{ $t("page.condominium.emptyList") }}
        </h6>
      </template>
      <template #cell(selected)="{ item }">
        <template v-if="isSelected(item)">
          <span aria-hidden="true">&check;</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
        </template>
      </template>
      <template #head()="data">
        <div
          :class="{ clickable: data.field.sort }"
          @click="data.field.sort && setSort(data.column)"
        >
          {{ $t(data.label) }}
          <svg
            v-if="data.column === sortBy"
            viewBox="0 0 16 16"
            class="sort-icon"
          >
            <path fill-rule="evenodd" :d="sortIcon"></path>
          </svg>
        </div>
      </template>
      <template #cell(fullName)="data">
        <b-avatar class="avatar mr-3" :src="data.item.photo" />
        {{ data.value }}
      </template>
      <template #cell(email)="data">
        {{ getMail(data.item) }}
      </template>
      <template #cell(telephone)="data">
        {{ data.value }}
      </template>
      <template #cell(status)="data">
        <b-badge
          class="text-white font-weight-normal"
          :variant="userStatusVariant[data.value]"
        >
          {{ $t(data.value) }}
        </b-badge>
      </template>
      <template #cell(role)="data">
        {{ $is1Board ? data.value : $t(`userRoles.${data.value}`) }}
      </template>
    </b-table>
  </b-container>
</template>

<script>
import { appMixin, userMixin } from "./../../../mixins";

import SearchInput from "../../Base/BaseSearchInput";
import UserFiltersShow from "./UserFiltersShow";
import { difference, concat } from "lodash";
export default {
  components: {
    SearchInput,
    UserFiltersShow
  },
  props: {
    isNewCommunication: { type: Boolean, default: true }
  },
  watch: {
    selectedRecipients: {
      handler: function(selectedRecipients) {
        this.$emit("isSelected", selectedRecipients.length > 0);
      },
      immediate: true
    }
  },
  mixins: [appMixin, userMixin],
  data() {
    return {
      fields: [
        { key: "selected", label: "selected" },
        { key: "fullName", label: "nameAndSurname", sort: true },
        { key: "email", label: "email", sort: true },
        { key: "telephone", label: "telephone", sort: false },
        { key: "status", label: "status", sort: false },
        { key: "role", label: "roleInCondominium", sort: true }
      ],
      consumerRoleOptions: [
        { label: "userRoles.doorman", value: "doorman" },
        { label: "userRoles.owner", value: "owner" },
        { label: "userRoles.tenant", value: "tenant" },
        { label: "userRoles.director", value: "director" }
      ],
      userStatusVariant: {
        active: "green",
        notverified: "cyan",
        invited: "yellow"
      },
      dataLoaded: false,
      totalRows: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: [],
      searchInput: "",
      filterOn: ["role"],
      filterRole: [],
      groupsLoading: false,
      groupsError: null
    };
  },
  created() {
    this.retrieveData();
  },
  computed: {
    groupUserFilters: {
      get() {
        return this.$store.getters["communication/getGroupUserFilters"];
      },
      set(value) {
        this.$store.commit("communication/SET_GROUP_USER_FILTERS", value);
      }
    },
    users() {
      return this.$store.getters["communication/getUsers"];
    },
    formatedUsers() {
      if (this.dataLoaded && this.users.length > 0) {
        return this.users.map((item, index) => {
          return {
            index,
            id: item._id,
            photo: this.getUserProfilePhoto(item.profile),
            fullName: this.getUserFullName(item.profile),
            email: item.email,
            telephone: this.getUserTelephone(item),
            role: this.getUserRole(item.condominiums),
            type: item.type,
            status: item.status
          };
        });
      } else return [];
    },
    sortIcon() {
      return this.sortDesc
        ? "M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4z"
        : "M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z";
    },
    filterSearch: {
      get() {
        return this.$store.getters["communication/getFilterSearch"];
      },
      set(value) {
        this.$store.commit("communication/SET_FILTER_SEARCH", value);
      }
    },
    recipients: {
      get() {
        return this.$store.getters["communication/getRecipients"];
      },
      set(value) {
        this.$store.commit("communication/SET_FORM_RECIPIENTS", value);
      }
    },
    filteredRecipients() {
      return this.formatedUsers.filter(user =>
        user.fullName.toLowerCase().includes(this.filterSearch.toLowerCase())
      );
    },
    selectedRecipients() {
      let newRecipients = concat(this.recipients, this.recipientsToAdd);
      newRecipients = difference(newRecipients, this.recipientsToRemove);
      return this.formatedUsers.filter(r => {
        return newRecipients.includes(r.id);
      });
    },
    recipientsToAdd: {
      get() {
        return this.$store.getters["communication/getEditRecipients"].add;
      },
      set(value) {
        this.$store.commit("communication/SET_RECIPIENTS_TO_ADD", value);
      }
    },
    recipientsToRemove: {
      get() {
        return this.$store.getters["communication/getEditRecipients"].remove;
      },
      set(value) {
        this.$store.commit("communication/SET_RECIPIENTS_TO_REMOVE", value);
      }
    },
    allGroups() {
      return this.$store.getters["communication/getActiveGroups"];
    }
  },
  methods: {
    getUserProfilePhoto(profile) {
      return this.$_getUserProfilePhoto(profile);
    },
    getUserFullName(profile) {
      if (profile?.name && profile?.surname) {
        return `${profile.name} ${profile.surname}`;
      } else return "-";
    },
    getUserTelephone(user) {
      return (
        user?.identities?.find(item => {
          return item.type === "mobile" && item.status === "verified";
        })?.identityId || "-"
      );
    },
    getUserRole(condominiums) {
      return condominiums?.[0]?.role ?? "-";
    },
    getMail(data) {
      if (data.type !== "guest") return data.email;
      return "-";
    },
    async retrieveData() {
      try {
        this.groupsLoading = true;
        await this.$store.dispatch("communication/retrieveActiveGroups");
        // if (!this.isNewCommunication) {
        //   const groups = this.$store.getters["communication/getActiveGroups"];
        //   this.$store.commit("communication/SET_GROUP_USER_FILTERS", {
        //     status: ["active", "invited", "notverified", "suspended"],
        //     groups: groups.map(g => g._id)
        //   });
        // }
        await this.$store.dispatch("communication/retrieveGroupUsers");
      } catch (error) {
        console.log(error);
        this.groupsError = error;
      } finally {
        this.dataLoaded = true;
        this.groupsLoading = false;
      }
    },
    setSort(sortBy) {
      if (this.sortBy !== sortBy) {
        this.sortBy = sortBy;
        this.sortDesc = false;
      } else {
        this.sortDesc = !this.sortDesc;
      }
    },
    onRowClicked(item) {
      // if new communication
      if (this.isNewCommunication) {
        if (this.isSelected(item)) {
          const newSelected = [...this.recipients];
          const idx = this.recipients.findIndex(r => r === item.id);
          newSelected.splice(idx, 1);
          this.recipients = newSelected;
        } else {
          this.recipients = [...this.recipients, item.id];
        }
      }
      // If communication for edit
      else {
        if (this.isSelected(item)) {
          if (this.recipients.includes(item.id)) {
            this.recipientsToRemove = [...this.recipientsToRemove, item.id];
          }
          this.recipientsToAdd = this.recipientsToAdd.filter(
            a => a !== item.id
          );
        } else {
          if (!this.recipients.includes(item.id)) {
            this.recipientsToAdd = [...this.recipientsToAdd, item.id];
          }
          this.recipientsToRemove = this.recipientsToRemove.filter(
            a => a !== item.id
          );
        }
      }
      this.$emit("isSelected", this.selectedRecipients.length > 0);
    },
    selectAllRows() {
      this.recipients = this.filteredRecipients.map(({ id }) => id);
    },
    clearSelected() {
      this.recipients = [];
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
    },
    arrayRemove(arr, value) {
      return arr.filter(function(ele) {
        return ele !== value;
      });
    },
    customFilter(data, filter) {
      return filter.includes(data.role);
    },
    isSelected(item) {
      return this.selectedRecipients.find(r => r.id === item.id);
    },
    async applyFilters({ filters, onSuccess } = {}) {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        this.groupUserFilters = filters;
        await this.$store.dispatch("communication/retrieveGroupUsers");
        if (this.isNewCommunication) this.clearSelected();
        if (onSuccess) onSuccess();
      } catch (error) {
      } finally {
        this.$store.commit("loader/RESET");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.sort-icon {
  width: 20px;
}
.avatar {
  --size: 30px;
  width: var(--size);
  height: var(--size);
}

.selected-recipients {
  margin: 10px 0 30px 0;
  padding: 0 14px;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.075);
  &__header {
    font-weight: bold;
  }
  &__item {
    padding: 5px 10px;
    font-weight: 500;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    width: calc(100% + 30px);
    &:hover {
      background: rgba(0, 0, 0, 0.075);
    }
  }
}
</style>
