<template>
  <b-modal
    v-model="dialogState"
    size="lg"
    header-class="pb-1"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @show="resetForm"
  >
    <template v-slot:modal-title>
      <h6 class="font-weight-bold text-secondary mb-0">
        {{ $t("select.consumerStatus") }}
      </h6>
    </template>
    <template v-slot:default>
      <b-form-group class="mb-5">
        <b-form-checkbox-group
          v-model="appliedFilters.status"
          id="recipients-status"
        >
          <b-row class="no-gutters">
            <b-col
              v-for="(status, index) in consumerStatus"
              :key="index"
              class="col-12 col-sm-6 col-md-3"
            >
              <b-form-checkbox :value="status.value">
                <b-badge
                  class="text-white font-weight-normal"
                  :variant="status.variant"
                >
                  {{ $t(status.label) }}
                </b-badge>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-checkbox-group>
      </b-form-group>

      <b-form-group class="mb-3">
        <template #label>
          <h6 class="font-weight-bold text-secondary mb-0">
            {{ $t("select.group") }}
          </h6>
          <hr class="mb-3 mt-1 mx-n3" />
        </template>
        <b-form-checkbox-group
          v-model="appliedFilters.groups"
          id="recipients-groups"
          class="recipients__groups__container"
        >
          <b-row class="no-gutters">
            <b-col
              v-for="(group, index) in groups"
              :key="index"
              class="col-12 col-sm-6 col-md-3"
            >
              <b-form-checkbox
                class="font-weight-normal mb-1 group__checkbox"
                :value="group._id"
              >
                {{ group.name }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form-checkbox-group>
      </b-form-group>

      <hr class="my-3 mx-n3" />

      <b-row class="no-gutters justify-content-end">
        <b-button variant="secondary" class="mr-1" @click="closeModal">
          {{ $t("button.cancel") }}
        </b-button>
        <b-button
          variant="primary"
          class="text-white"
          :disabled="invalidForm"
          @click="proceed"
        >
          {{ $t("button.confirm") }}
        </b-button>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import {
  userCommunicationGroupFilters,
  userStatus
} from "../../../constants/user";

export default {
  props: {
    state: { type: Boolean, default: false },
    filters: { type: Object, required: false, default: undefined },
    groups: { type: Array, default: () => [] }
  },
  data() {
    return {
      appliedFilters: userCommunicationGroupFilters,
      consumerStatus: userStatus
    };
  },
  computed: {
    dialogState: {
      get() {
        return this.state;
      },
      set() {
        this.closeModal();
      }
    },
    invalidForm() {
      return (
        this.appliedFilters?.status?.length === 0 ||
        this.appliedFilters?.groups?.length === 0
      );
    }
  },
  methods: {
    resetForm() {
      this.appliedFilters = this.filters
        ? { ...this.filters }
        : { ...userCommunicationGroupFilters };
    },
    closeModal() {
      this.$emit("close");
    },
    proceed() {
      this.$emit("proceed", this.appliedFilters);
    }
  }
};
</script>

<style style="scss" scoped>
.recipients__groups__container {
  min-height: 160px;
  height: calc(100vh - 350px);
  overflow-y: auto;
}

.group__checkbox {
  font-size: 0.9rem;
}
</style>
