<template>
  <div>
    <div>
      <span class="font-weight-bold mr-3">
        {{ $t("page.communication.statusAndGroupSelected") }}
      </span>
      <b-button
        variant="none"
        class="p-0 m-0"
        :disabled="groupsLoading || groupsError"
        @click="openFilterModal"
      >
        <b-icon icon="pencil-square" variant="primary" />
      </b-button>
    </div>
    <div>
      <template v-for="(s, index) in status">
        <b-badge
          :key="index"
          class="text-white font-weight-normal ml-1"
          :variant="statusVariant[s]"
        >
          {{ $t(s) }}
        </b-badge>
      </template>
    </div>
    <div v-if="groupsError">
      Groups are not available.
    </div>
    <div v-else-if="!groupsLoading">
      <template v-for="(g, index) in selectedGroupLabels">
        <b-badge
          :key="index"
          class="text-white font-weight-normal ml-1"
          variant="cyan-500"
        >
          {{ g }}
        </b-badge>
      </template>
    </div>

    <communication-user-filters
      :state="filterModalState"
      :filters="{ status, groups }"
      :groups="allGroups"
      @close="closeFilterModal"
      @proceed="updateFilters"
    />
  </div>
</template>

<script>
import CommunicationUserFilters from "./UserFilters";

import { userStatusVariant } from "../../../constants/user";

export default {
  props: {
    status: { type: Array, default: () => [] },
    groups: { type: Array, default: () => [] },
    groupsLoading: { type: Boolean, default: false },
    groupsError: { type: [Object, null], default: null },
    allGroups: { type: Array, default: () => [] }
  },
  components: {
    CommunicationUserFilters
  },
  data() {
    return {
      statusVariant: userStatusVariant,
      filterModalState: false
    };
  },
  mounted() {},
  computed: {
    selectedGroupLabels() {
      return this.groups.reduce(
        (acc, cur) => [
          ...acc,
          this.allGroups?.find(g => cur === g._id)?.name || "-"
        ],
        []
      );
    }
  },
  methods: {
    openFilterModal() {
      this.filterModalState = true;
    },
    closeFilterModal() {
      this.filterModalState = false;
    },
    updateFilters(filters) {
      this.$emit("update", {
        filters,
        onSuccess: () => (this.filterModalState = false)
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
